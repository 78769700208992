body {}

.app {
  /* min-height: 100vh; */
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.app .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.app .trigger:hover {
  color: #1890ff;
}

.app .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.app .site-layout-background {
  background: #fff;
}

.app .ant-layout-header {
  display: flex;
  align-items: center;
}

.app-loading-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* .ant-layout-rtl {
  font-family: Arial, Helvetica, sans-serif;
} */